import './custom.css'
import { Component } from 'react';

import { AllgemeineEinstellungenDialog, AnzeigekonfigurationenVerwaltenDialog, CustomFormsRenderer, LieferlandDialog, Registrierung, SpeditionDialog, SprachauswahlDialog, StartDialogRenderer, TerminalOeffnungszeitenDialog, TorDialog, UnbekannteSpeditionenDialog, withNavigationbar, withStammdatenSidebar } from '@5minds/schauflogistics-abwicklung-modules-dialogs';

export default class App extends Component {
  static displayName = App.name;

  render() {
    return (
      <div style={{ height: "100%" }}>
        <CustomFormsRenderer
          components={{Registrierung}}
        />
        <StartDialogRenderer
          components={{
            LieferlandDialog: withNavigationbar(withStammdatenSidebar(LieferlandDialog)),
            SpeditionDialog: withNavigationbar(withStammdatenSidebar(SpeditionDialog)),
            SprachauswahlDialog: SprachauswahlDialog,
            TerminalOeffnungszeitenDialog: withNavigationbar(withStammdatenSidebar(TerminalOeffnungszeitenDialog)),
            TorDialog: withNavigationbar(withStammdatenSidebar(TorDialog)),
            UnbekannteSpeditionenDialog: withNavigationbar(withStammdatenSidebar(UnbekannteSpeditionenDialog)),
            AllgemeineEinstellungenDialog: withNavigationbar(withStammdatenSidebar(AllgemeineEinstellungenDialog)),
            AnzeigekonfigurationenVerwaltenDialog: withNavigationbar(withStammdatenSidebar(AnzeigekonfigurationenVerwaltenDialog)),
          }}
          stammdatenDialogs={{
            LieferlandDialog: 'Lieferländer',
            SpeditionDialog: 'Speditionen',
            TerminalOeffnungszeitenDialog: 'Terminals',
            TorDialog: 'Tore',
            UnbekannteSpeditionenDialog: 'Unbekannte Speditionen',
            AllgemeineEinstellungenDialog: 'Allgemeine Einstellungen',
            AnzeigekonfigurationenVerwaltenDialog: 'Anzeigen',
          }}
        />
      </div>
    );
  }
}
